import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import usePageStyles from '../hooks/usePageStyles';
import HeaderSpacer from '../components/Helpers/HeaderSpacer';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import ArchiveJournalsList from '../components/JournalsList/ArchiveJournalsList';
import { getTypeArchiveUrl } from '../utilities/permalinks';

function Archive(props) {
  const {
    data: {
      type,
      archiveJournals: { nodes: journals },
    },
  } = props;

  usePageStyles({ backgroundColor: '#929598' });
  return (
    <>
      <Seo post={type} />
      <HeaderSpacer />
      <Breadcrumbs data={[{ label: type.name }]} />
      <ArchiveJournalsList
        journals={journals}
        typeBreadcrumbs={[
          { label: type.name, url: getTypeArchiveUrl(type.slug) },
        ]}
      />
    </>
  );
}

Archive.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    archiveJournals: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
};
export default Archive;

export const query = graphql`
  query archiveQuery($typeId: String) {
    type: wpType(id: { eq: $typeId }) {
      seo {
        ...YoastTaxonomySEOContent
      }
      id
      slug
      name
    }
    archiveJournals: allWpJournal(
      filter: {
        types: { nodes: { elemMatch: { id: { eq: $typeId } } } }
        years: { nodes: { elemMatch: { count: { ne: 0 } } } }
        featuredImageId: { ne: null }
      }
    ) {
      nodes {
        ...ArchiveJournalsListingFields
      }
    }
  }
`;
