import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getSingleJournalUrl } from '../../utilities/permalinks';
import * as styles from './ArchiveJournalsList.module.scss';
import EmptyContent from '../Helpers/EmptyContent';

function ArchiveJournalsList(props) {
  const { journals, typeBreadcrumbs } = props;

  /**
   * =======================================
   * Find a solution with better performance
   * =======================================
   */
  // Extract all years from journals
  const allYears = [];
  journals.forEach((journal) => {
    journal.years.nodes.forEach((year) => {
      allYears.push(year);
    });
  });

  // Remove all duplicates
  const uniqueYears = [
    ...allYears.filter(
      (year, index, self) => self.findIndex((t) => t.id === year.id) === index,
    ),
  ];

  // Sort years in ascending order
  const sortedYears = uniqueYears.sort((a, b) => a.name - b.name);

  const renderEmptyContent = () => <EmptyContent message="No posts found in this category." />;

  const renderImage = (image) => {
    const width = image?.localFile?.originalImage?.original?.width;
    const height = image?.localFile?.originalImage?.original?.height;
    if (width / height > 1) {
      // landscape image
      return (
        <>
          <GatsbyImage
            className="d-md-none"
            image={getImage(image.localFile.mobileImageLandscape)}
            alt={image.altText}
          />
          <GatsbyImage
            className="d-none d-md-block"
            image={getImage(image.localFile.desktopImageLandscape)}
            alt={image.altText}
          />
        </>
      );
    }
    // portrait image
    return (
      <>
        <GatsbyImage
          className="d-md-none"
          image={getImage(image.localFile.mobileImagePortrait)}
          alt={image.altText}
        />
        <GatsbyImage
          className="d-none d-md-block"
          image={getImage(image.localFile.desktopImagePortrait)}
          alt={image.altText}
        />
      </>
    );
  };

  const renderContent = () => {
    if (!sortedYears.length) return renderEmptyContent();

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Accordion className={styles.archiveJournalsList} alwaysOpen>
              {sortedYears.map((year, index) => (
                <Accordion.Item
                  eventKey={index}
                  key={`archive-year-${year.slug}`}
                >
                  <Accordion.Header className={styles.yearTitle} as="div">
                    {year.name}
                  </Accordion.Header>
                  <Accordion.Body className={styles.journalsList} as="ul">
                    {journals
                      .filter((journal) => {
                        const ret = journal.years.nodes.find(
                          (el) => el.id === year.id,
                        );
                        return !!ret;
                      })
                      .map((journal) => {
                        const image = journal.featuredImage.node;
                        const postBreadcrumbs = typeBreadcrumbs && Array.isArray((typeBreadcrumbs))
                          ? [...typeBreadcrumbs, { label: year.name }]
                          : typeBreadcrumbs;

                        return (
                          <li key={journal.id}>
                            <Link
                              to={getSingleJournalUrl(journal.slug)}
                              state={{ breadcrumbs: postBreadcrumbs }}
                            >
                              {renderImage(image)}
                            </Link>
                          </li>
                        );
                      })}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    );
  };

  return renderContent();
}

ArchiveJournalsList.defaultProps = {
  journals: [],
  typeBreadcrumbs: null,
};
ArchiveJournalsList.propTypes = {
  journals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      years: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ),
      }),
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          altText: PropTypes.string.isRequired,
          localFile: PropTypes.shape({
            originalImage: PropTypes.shape({}).isRequired,
            mobileImagePortrait: PropTypes.shape({}).isRequired,
            mobileImageLandscape: PropTypes.shape({}).isRequired,
            desktopImagePortrait: PropTypes.shape({}).isRequired,
            desktopImageLandscape: PropTypes.shape({}).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }),
  ),
  typeBreadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ArchiveJournalsList;
